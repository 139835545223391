const SELECTOR_TEXT_BOX = ".content-text-text";
const SELECTOR_BUTTON_MORE = ".content-text-mehr";

const STATE_MORE = "more";
const STATE_LESS = "less";

class ContentText extends HTMLElement {
  connectedCallback() {
    this.setElements();
    this.bindEvents();
    this.calculateInitialTextState();
  }

  setElements() {
    this.textBoxElem = this.querySelector(SELECTOR_TEXT_BOX);
    this.buttonElem = this.querySelector(SELECTOR_BUTTON_MORE);
  }

  bindEvents() {
    if(this.buttonElem) {
      this.buttonElem.addEventListener('click', (event) => this.toggleText(event));
    }
    window.addEventListener('resize', () => this.calculateButtonMoreState());
  }

  calculateInitialTextState() {
    this.textBoxHeight = this.textBoxElem.clientHeight;
    this.calculateButtonMoreState();
  }

  toggleText(event) {
    this.textBoxElem.style.maxHeight = event.target.dataset.state === STATE_MORE ? `unset` : `${this.textBoxHeight}px`;
    event.target.setAttribute("data-state", event.target.dataset.state === STATE_MORE ? STATE_LESS : STATE_MORE);
    event.target.innerHTML = event.target.dataset.state === STATE_MORE ? "Weiterlesen" : "Weniger";
    if(event.target.innerHTML == "Weiterlesen") {
      this.textBoxElem.classList.add("weiterlesen");
    } else {
      this.textBoxElem.classList.remove("weiterlesen");
    }
  }

  calculateButtonMoreState() {
    if(this.buttonElem) {
      this.buttonElem.style.display = (this.textBoxElem.scrollHeight > this.textBoxElem.offsetHeight) ? 'inline-flex' : 'none';
    }
  }
}

if (!customElements.get("content-text")) {
  customElements.define("content-text", ContentText);
}

